<template>
  <div class="className" />
</template>

<script>
import echarts from 'echarts' // echarts theme
import { debounce } from '@/utils/util'
require('echarts/theme/macarons')
export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '100%'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      default: () => { return {} }
    },
    name: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    }
  },
  mounted () {
    this.initChart()
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHandler)
      setTimeout(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 600)
    }
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler)
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    setOptions ({ arr } = {}) {
      this.chart.setOption({
        legend: {
          orient: 'vertical',
          left: 10, // 设置合适的 left 值
          top: 'left',
          textStyle: {
            color: '#fff'
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          }
        },
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: [50, 80],
            center: ['50%', '50%'],
            roseType: 'area',
            label: {
              normal: {
                show: true,
                textStyle: {
                  fontSize: 12
                },
                formatter: '{b} {c}台'
              }
            },
            itemStyle: {
              borderRadius: 8
              // color: function (colors) {
              //   var colorList = [
              //     '#489bfb',
              //     '#bbe7f2',
              //     '#edc910'
              //   ]
              //   return colorList(colors.dataIndex)
              // }
            },
            data: arr
          }
        ]
      })
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    }
  }
}
</script>
<style scss scoped>
.className {
  width: 100%;
  height: 20vh;
}
</style>
