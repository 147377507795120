import { render, staticRenderFns } from "./bings.vue?vue&type=template&id=acb5bc32&scoped=true"
import script from "./bings.vue?vue&type=script&lang=js"
export * from "./bings.vue?vue&type=script&lang=js"
import style0 from "./bings.vue?vue&type=style&index=0&id=acb5bc32&prod&scss=true&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acb5bc32",
  null
  
)

export default component.exports